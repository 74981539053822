import { cn } from '@/lib/utils';

export function TypographyH4({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<h4
			className={cn(
				'scroll-m-20 pb-2 capitalize text-xl font-bold leading-relaxed font-title tracking-tight text-white first:mt-0',
				className
			)}>
			{children}
		</h4>
	);
}
