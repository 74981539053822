'use client';

import { FC, forwardRef } from 'react';
import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuLink,
	NavigationMenuList,
	NavigationMenuTrigger,
	navigationMenuTriggerStyle,
} from '../ui/navigationMenu';
import { LinkComponent } from '.';
import Link from 'next/link';
import { cn } from '@/lib/utils';

export const DesktopNavbar: FC<{ components: LinkComponent[] }> = ({
	components,
}) => {
	return (
		<NavigationMenu className='lg:block hidden'>
			<NavigationMenuList>
				{components.map((component, index) => {
					if (component.type === 'dropdown') {
						return (
							<NavigationMenuItem key={index}>
								<NavigationMenuTrigger>{component.title}</NavigationMenuTrigger>
								<NavigationMenuContent>
									<ul className='grid w-[400px] gap-3 p-4 md:w-[300px] grid-cols-1 lg:w-[360px] '>
										{component.items?.map((item, index) => (
											<ListItem
												key={item.title}
												title={item.title}
												icon={item?.icon}
												href={item.href}>
												{item.summary}
											</ListItem>
										))}
									</ul>
								</NavigationMenuContent>
							</NavigationMenuItem>
						);
					}

					if (component.type === 'link') {
						return (
							<NavigationMenuItem key={index}>
								<Link href={component.href ?? ''} legacyBehavior passHref>
									<NavigationMenuLink className={navigationMenuTriggerStyle()}>
										{component.title}
									</NavigationMenuLink>
								</Link>
							</NavigationMenuItem>
						);
					}
				})}
			</NavigationMenuList>
		</NavigationMenu>
	);
};

const ListItem = forwardRef<
	React.ElementRef<'a'>,
	React.ComponentPropsWithoutRef<'a'> & { icon: React.ReactNode }
>(({ className, title, icon, children, ...props }, ref) => {
	return (
		<li className='group'>
			<NavigationMenuLink asChild>
				<a
					ref={ref}
					className={cn(
						'flex select-none items-center space-x-3 rounded-md p-3 leading-none no-underline outline-none transition-colors border border-transparent hover:border-accent hover:bg-popover/10 hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
						className
					)}
					{...props}>
					{icon && (
						<div className=' text-foreground/70 group-hover:text-accent'>
							{icon}
						</div>
					)}
					<div className='flex flex-col space-y-1'>
						<p className='text-lg font-semibold text-foreground/70 group-hover:text-foreground leading-none'>
							{title}
						</p>
						<p className='line-clamp-2 text-sm leading-snug text-muted-foreground/70'>
							{children}
						</p>
					</div>
				</a>
			</NavigationMenuLink>
		</li>
	);
});
ListItem.displayName = 'ListItem';
